import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Carousel } from 'app/components/OfferContent/Carousel'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  beURL?: string
  cta?: ButtonProps
  description?: string
  email?: string
  galleryImages?: ImageProps[]
  galleryThumbnails?: ImageProps[]
  languageCode: string
  subtitle?: string
  title: string
}

export const EventContent = memo(function EventContent({
  description,
  beURL,
  cta,
  email,
  galleryImages,
  galleryThumbnails,
  languageCode,
  subtitle,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {galleryImages ? (
          <Carousel
            images={galleryImages}
            languageCode={languageCode}
            thumbnails={galleryThumbnails}
          />
        ) : null}
      </Wrapper>

      <Banner>
        <Rumble />

        <BannerTitle>
          {useVocabularyData('event-buttons-title', languageCode)}
        </BannerTitle>

        {beURL || cta ? (
          <Buttons dial={5} row>
            {beURL ? (
              <Button
                label={useVocabularyData('book', languageCode)}
                target="_blank"
                URL={beURL}
              />
            ) : null}
            {cta ? <Button {...cta} variant="primary" /> : null}
          </Buttons>
        ) : null}
      </Banner>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 7.0625rem 19.722vw 10rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 3.375rem;
    padding-bottom: 5rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 0.9375rem;
    line-height: 1.5rem;
    margin-top: 0.125rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const Banner = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 6.0625rem 1.875rem 7.75rem;
  position: relative;
  text-align: center;

  > svg {
    width: 2.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1023px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.125rem;
    padding-bottom: 4.25rem;
  }
`

const BannerTitle = styled.div`
  max-width: 32.5rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 3.125rem;
  margin: 0 auto 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Buttons = styled(FlexBox)`
  a {
    min-width: 12.5rem;
    margin: 0 0.375rem;
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 2rem;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 0.625rem 0 0;
      }
    }
  }
`
